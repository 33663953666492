/**
Ion.RangeSlider, 2.3.1
© Denis Ineshin, 2010 - 2019, IonDen.com
Build date: 2019-12-19 16:51:02
*/

.irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-family: Arial, sans-serif;
  }
  
  .irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
  }
  
  .irs-bar {
    position: absolute;
    display: block;
    inset-inline-start: 0;
    width: 0;
  }
  
  .irs-shadow {
    position: absolute;
    display: none;
    inset-inline-start: 0;
    width: 0;
  }
  
  .irs-handle {
    position: absolute;
    display: block;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
  
    &.type_last {
      z-index: 1;
    }
  }
  
  .irs-min, .irs-max {
    position: absolute;
    display: block;
    cursor: default;
  }
  
  .irs-min {
    inset-inline-start: 0;
  }
  
  .irs-max {
    inset-inline-end: 0;
  }
  
  .irs-from, .irs-to, .irs-single {
    position: absolute;
    display: block;
    top: 0;
    inset-inline-start: 0;
    cursor: default;
    white-space: nowrap;
  }
  
  .irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 20px;
  }
  
  .irs-with-grid .irs-grid {
    display: block;
  }
  
  .irs-grid-pol {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 1px;
    height: 8px;
    background: #000;
  
    &.small {
      height: 4px;
    }
  }
  
  .irs-grid-text {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #000;
  }
  
  .irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    inset-inline-start: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
  }
  
  .lt-ie9 .irs-disable-mask {
    background: #000;
    filter: alpha(opacity = 0);
    cursor: not-allowed;
  }
  
  .irs-disabled {
    opacity: 0.4;
  }
  
  .irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    inset-inline-start: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
  }
  
  .irs--flat {
    height: 40px;
  
    &.irs-with-grid {
      height: 60px;
    }
  
    .irs-line {
      top: 25px;
      height: 12px;
      background-color: $gray-200;
      border-radius: 4px;
    }
  
    .irs-bar {
      top: 25px;
      height: 12px;
      background-color: $primary;
    }
  
    .irs-bar--single {
      border-radius: 4px 0 0 4px;
    }
  
    .irs-shadow {
      height: 1px;
      bottom: 16px;
      background-color: $gray-200;
    }
  
    .irs-handle {
      top: 22px;
      width: 16px;
      height: 18px;
      background-color: transparent;
  
      > i:first-child {
        position: absolute;
        display: block;
        top: 0;
        inset-inline-start: 50%;
        width: 2px;
        height: 100%;
        margin-inline-start: -1px;
        background-color: $primary;
      }
  
      &.state_hover > i:first-child, &:hover > i:first-child {
        background-color: $primary;
      }
    }
  
    .irs-min, .irs-max {
      top: 0;
      padding: 1px 3px;
      color: #999;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      background-color: $gray-200;
      border-radius: 4px;
    }
  
    .irs-from, .irs-to, .irs-single {
      color: white;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      background-color: $primary;
      border-radius: 4px;
    }
  
    .irs-from:before, .irs-to:before, .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      inset-inline-start: 50%;
      width: 0;
      height: 0;
      margin-inline-start: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-block-start-color: $primary;
    }
  
    .irs-grid-pol {
      background-color: $gray-200;
    }
  
    .irs-grid-text {
      color: #999;
    }
  }
  
  .irs--big {
    height: 55px;
  
    &.irs-with-grid {
      height: 70px;
    }
  
    .irs-line {
      top: 33px;
      height: 12px;
      background-color: white;
      background: linear-gradient(to bottom, #ddd -50%, white 150%);
      border: 1px solid #ccc;
      border-radius: 12px;
    }
  
    .irs-bar {
      top: 33px;
      height: 12px;
      background-color: #92bce0;
      border: 1px solid #428bca;
      background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
      box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
    }
  
    .irs-bar--single {
      border-radius: 12px 0 0 12px;
    }
  
    .irs-shadow {
      height: 1px;
      bottom: 16px;
      background-color: rgba(66, 139, 202, 0.5);
    }
  
    .irs-handle {
      top: 25px;
      width: 30px;
      height: 30px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #cbcfd5;
      background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
      border-radius: 30px;
  
      &.state_hover, &:hover {
        border-color: rgba(0, 0, 0, 0.45);
        background-color: #939ba7;
        background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
      }
    }
  
    .irs-min, .irs-max {
      top: 0;
      padding: 1px 5px;
      color: white;
      text-shadow: none;
      background-color: #9f9f9f;
      border-radius: 3px;
    }
  
    .irs-from, .irs-to, .irs-single {
      color: white;
      text-shadow: none;
      padding: 1px 5px;
      background-color: #428bca;
      background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
      border-radius: 3px;
    }
  
    .irs-grid-pol {
      background-color: #428bca;
    }
  
    .irs-grid-text {
      color: #428bca;
    }
  }
  
  .irs--modern {
    height: 55px;
  
    &.irs-with-grid {
      height: 55px;
    }
  
    .irs-line {
      top: 25px;
      height: 5px;
      background-color: #d1d6e0;
      background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
      border: 1px solid #a3adc1;
      border-block-end-width: 0;
      border-radius: 5px;
    }
  
    .irs-bar {
      top: 25px;
      height: 5px;
      background: #20b426;
      background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
    }
  
    .irs-bar--single {
      border-radius: 5px 0 0 5px;
    }
  
    .irs-shadow {
      height: 1px;
      bottom: 21px;
      background-color: rgba(209, 214, 224, 0.5);
    }
  
    .irs-handle {
      top: 37px;
      width: 12px;
      height: 13px;
      border: 1px solid #a3adc1;
      border-block-start-width: 0;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 3px 3px;
  
      > i {
        &:nth-child(1) {
          position: absolute;
          display: block;
          top: -4px;
          inset-inline-start: 1px;
          width: 6px;
          height: 6px;
          border: 1px solid #a3adc1;
          background: white;
          transform: rotate(45deg);
        }
  
        &:nth-child(2) {
          position: absolute;
          display: block;
          box-sizing: border-box;
          top: 0;
          inset-inline-start: 0;
          width: 10px;
          height: 12px;
          background: #e9e6e6;
          background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
          border-radius: 0 0 3px 3px;
        }
  
        &:nth-child(3) {
          position: absolute;
          display: block;
          box-sizing: border-box;
          top: 3px;
          inset-inline-start: 3px;
          width: 4px;
          height: 5px;
          border-inline-start: 1px solid #a3adc1;
          border-inline-end: 1px solid #a3adc1;
        }
      }
  
      &.state_hover, &:hover {
        border-color: #7685a2;
        background: #c3c7cd;
        background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
      }
  
      &.state_hover > i:nth-child(1), &:hover > i:nth-child(1) {
        border-color: #7685a2;
      }
  
      &.state_hover > i:nth-child(3), &:hover > i:nth-child(3) {
        border-color: #48536a;
      }
    }
  
    .irs-min, .irs-max {
      top: 0;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      color: white;
      background-color: #d1d6e0;
      border-radius: 5px;
    }
  
    .irs-from, .irs-to, .irs-single {
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      background-color: #20b426;
      color: white;
      border-radius: 5px;
    }
  
    .irs-from:before, .irs-to:before, .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      inset-inline-start: 50%;
      width: 0;
      height: 0;
      margin-inline-start: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-block-start-color: #20b426;
    }
  
    .irs-grid {
      height: 25px;
    }
  
    .irs-grid-pol {
      background-color: #dedede;
    }
  
    .irs-grid-text {
      color: silver;
      font-size: 13px;
    }
  }
  
  .irs--sharp {
    height: 50px;
    font-size: 12px;
    line-height: 1;
  
    &.irs-with-grid {
      height: 57px;
    }
  
    .irs-line {
      top: 30px;
      height: 2px;
      background-color: black;
      border-radius: 2px;
    }
  
    .irs-bar {
      top: 30px;
      height: 2px;
      background-color: #ee22fa;
    }
  
    .irs-bar--single {
      border-radius: 2px 0 0 2px;
    }
  
    .irs-shadow {
      height: 1px;
      bottom: 21px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    .irs-handle {
      top: 25px;
      width: 10px;
      height: 10px;
      background-color: #a804b2;
  
      > i:first-child {
        position: absolute;
        display: block;
        top: 100%;
        inset-inline-start: 0;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-block-start-color: #a804b2;
      }
  
      &.state_hover, &:hover {
        background-color: black;
      }
  
      &.state_hover > i:first-child, &:hover > i:first-child {
        border-block-start-color: black;
      }
    }
  
    .irs-min, .irs-max {
      color: white;
      font-size: 14px;
      line-height: 1;
      top: 0;
      padding: 3px 4px;
      opacity: 0.4;
      background-color: #a804b2;
      border-radius: 2px;
    }
  
    .irs-from, .irs-to, .irs-single {
      font-size: 14px;
      line-height: 1;
      text-shadow: none;
      padding: 3px 4px;
      background-color: #a804b2;
      color: white;
      border-radius: 2px;
    }
  
    .irs-from:before, .irs-to:before, .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      inset-inline-start: 50%;
      width: 0;
      height: 0;
      margin-inline-start: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-block-start-color: #a804b2;
    }
  
    .irs-grid {
      height: 25px;
    }
  
    .irs-grid-pol {
      background-color: #dedede;
    }
  
    .irs-grid-text {
      color: silver;
      font-size: 13px;
    }
  }
  
  .irs--round {
    height: 50px;
  
    &.irs-with-grid {
      height: 65px;
    }
  
    .irs-line {
      top: 36px;
      height: 4px;
      background-color: #dee4ec;
      border-radius: 4px;
    }
  
    .irs-bar {
      top: 36px;
      height: 4px;
      background-color: #006cfa;
    }
  
    .irs-bar--single {
      border-radius: 4px 0 0 4px;
    }
  
    .irs-shadow {
      height: 4px;
      bottom: 21px;
      background-color: rgba(222, 228, 236, 0.5);
    }
  
    .irs-handle {
      top: 26px;
      width: 24px;
      height: 24px;
      border: 4px solid #006cfa;
      background-color: white;
      border-radius: 24px;
      box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
  
      &.state_hover, &:hover {
        background-color: #f0f6ff;
      }
    }
  
    .irs-min, .irs-max {
      color: #333;
      font-size: 14px;
      line-height: 1;
      top: 0;
      padding: 3px 5px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  
    .irs-from, .irs-to, .irs-single {
      font-size: 14px;
      line-height: 1;
      text-shadow: none;
      padding: 3px 5px;
      background-color: #006cfa;
      color: white;
      border-radius: 4px;
    }
  
    .irs-from:before, .irs-to:before, .irs-single:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      inset-inline-start: 50%;
      width: 0;
      height: 0;
      margin-inline-start: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-block-start-color: #006cfa;
    }
  
    .irs-grid {
      height: 25px;
    }
  
    .irs-grid-pol {
      background-color: #dedede;
    }
  
    .irs-grid-text {
      color: silver;
      font-size: 13px;
    }
  }
  
  .irs--square {
    height: 50px;
  
    &.irs-with-grid {
      height: 60px;
    }
  
    .irs-line {
      top: 31px;
      height: 4px;
      background-color: #dedede;
    }
  
    .irs-bar {
      top: 31px;
      height: 4px;
      background-color: black;
    }
  
    .irs-shadow {
      height: 2px;
      bottom: 21px;
      background-color: #dedede;
    }
  
    .irs-handle {
      top: 25px;
      width: 16px;
      height: 16px;
      border: 3px solid black;
      background-color: white;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  
      &.state_hover, &:hover {
        background-color: #f0f6ff;
      }
    }
  
    .irs-min, .irs-max {
      color: #333;
      font-size: 14px;
      line-height: 1;
      top: 0;
      padding: 3px 5px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  
    .irs-from, .irs-to, .irs-single {
      font-size: 14px;
      line-height: 1;
      text-shadow: none;
      padding: 3px 5px;
      background-color: black;
      color: white;
    }
  
    .irs-grid {
      height: 25px;
    }
  
    .irs-grid-pol {
      background-color: #dedede;
    }
  
    .irs-grid-text {
      color: silver;
      font-size: 11px;
    }
  }
  
  .irs-bar, .irs-bar-edge {
    background-image: none;
    background-color: $primary;
    }
    .irs-primary {
    .irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
      background-color: $primary;
    }
    
    .irs-from::after, .irs-to::after, .irs-single::after {
      border-top-color: $primary;
    }
    }
    