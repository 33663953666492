

/* ############### FONTS IMPORT ############### */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");


@import "variables";

/* ######## Plugins ######## */
@import "plugins/accept-cookies";
@import "plugins/accordion";
@import "plugins/amazeui.datetimepicker";
@import "plugins/bootstrap-datepicker";
@import "plugins/buttons.bootstrap5";
@import "plugins/classic";
@import "plugins/cookies";
@import "plugins/countdown";
@import "plugins/dataTables.bootstrap5";
@import "plugins/dtree";
@import "plugins/fancy_fileupload";
@import "plugins/fileupload";
@import "plugins/fullcalendar";
@import "plugins/gallery";
@import "plugins/inputtags";
@import "plugins/ion.rangeSlider";
@import "plugins/ion.rangeSlider.skinFlat";
@import "plugins/jquery-ui-darggable";
@import "plugins/jquery.simple-dtpicker";
@import "plugins/jquery.growl";
@import "plugins/jquery.steps";
@import "plugins/jqvmap";
@import "plugins/leaflet";
@import "plugins/lightslider";
@import "plugins/monolith";
@import "plugins/morris";
@import "plugins/multislider";
@import "plugins/nano";
@import "plugins/nice-select";
@import "plugins/notifIt";
@import "plugins/owl.carousel";
@import "plugins/picker";
@import "plugins/prism";
@import "plugins/pscroll";
@import "plugins/quill.bubble";
@import "plugins/quill.snow";
@import "plugins/responsive.bootstrap5";
@import "plugins/richtext";
@import "plugins/select2";
@import "plugins/sidebar";
@import "plugins/simple-bar";
@import "plugins/smartphoto";
@import "plugins/spectrum";
@import "plugins/star-rating-svg";
@import "plugins/summernote";
@import "plugins/summernote1";
@import "plugins/sumoselect";
@import "plugins/sweetalert";
@import "plugins/tab-style";
@import "plugins/telephoneinput";
@import "plugins/timeline";
@import "plugins/treeview";
@import "plugins/twentytwenty";