/*! Pickr 1.8.2 MIT | https://github.com/Simonwep/pickr */

.pickr {
    position : relative;
    overflow : visible;
    transform: translateY(0);

    * {
        box-sizing        : border-box;
        outline           : none;
        border            : none;
        -webkit-appearance: none;
    }

    .pcr-button {
        position       : relative;
        height         : 2em;
        width          : 2em;
        padding        : 0.5em;
        cursor         : pointer;
        font-family    : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
        border-radius  : .15em;
        background     : url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="%2342445A" stroke-width="5px" stroke-linecap="round"><path d="M45,45L5,5"></path><path d="M45,5L5,45"></path></svg>') no-repeat center;
        background-size: 0;
        transition     : all 0.3s;

        &::before {
            position       : absolute;
            content        : '';
            top            : 0;
            left           : 0;
            width          : 100%;
            height         : 100%;
            background     : url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
            background-size: .5em;
            border-radius  : .15em;
            z-index        : -1;
            z-index        : initial;
        }

        &::after {
            position     : absolute;
            content      : '';
            top          : 0;
            left         : 0;
            height       : 100%;
            width        : 100%;
            transition   : background 0.3s;
            background   : var(--pcr-color);
            border-radius: .15em;
        }

        &.clear {
            background-size: 70%;

            &::before {
                opacity: 0;
            }

            &:focus {
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
            }
        }

        &.disabled {
            cursor: not-allowed;
        }
    }

    * {
        box-sizing        : border-box;
        outline           : none;
        border            : none;
        -webkit-appearance: none;
    }
}

.pcr-app * {
    box-sizing        : border-box;
    outline           : none;
    border            : none;
    -webkit-appearance: none;
}

.pickr {
    input {

        &:focus,
        &.pcr-active {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
        }
    }

    button {

        &:focus,
        &.pcr-active {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
        }
    }
}

.pcr-app {
    input {

        &:focus,
        &.pcr-active {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
        }
    }

    button {

        &:focus,
        &.pcr-active {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px var(--pcr-color);
        }
    }
}

.pickr {

    .pcr-palette,
    .pcr-slider {
        transition: box-shadow 0.3s;
    }
}

.pcr-app {

    .pcr-palette,
    .pcr-slider {
        transition: box-shadow 0.3s;
    }
}

.pickr {

    .pcr-palette:focus,
    .pcr-slider:focus {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(0, 0, 0, 0.25);
    }
}

.pcr-app {

    .pcr-palette:focus,
    .pcr-slider:focus {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(0, 0, 0, 0.25);
    }

    position      : fixed;
    display       : flex;
    flex-direction: column;
    z-index       : 100;
    border-radius : 0.1em;
    background    : $white;
    opacity       : 0;
    visibility    : hidden;
    transition    : opacity 0.3s,
    visibility 0s 0.3s;
    font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    Arial,
    sans-serif;
    box-shadow: 0 0.15em 1.5em 0 $black-1,
    0 0 1em 0 rgba(0, 0, 0, 0.03);
    inset-inline-start: 0;
    top : 0;

    &.visible {
        transition: opacity 0.3s;
        visibility: visible;
        opacity   : 1;
    }

    .pcr-swatches {
        display   : flex;
        flex-wrap : wrap;
        margin-block-start: 0.75em;

        &.pcr-last {
            margin: 0;
        }

        >button {
            font-size    : 1em;
            position     : relative;
            width        : calc(1.75em - 5px);
            height       : calc(1.75em - 5px);
            border-radius: 0.15em;
            cursor       : pointer;
            margin       : 2.5px;
            flex-shrink  : 0;
            justify-self : center;
            transition   : all 0.15s;
            overflow     : hidden;
            background   : transparent;
            z-index      : 1;

            &::before {
                position       : absolute;
                content        : '';
                top            : 0;
                left           : 0;
                width          : 100%;
                height         : 100%;
                background     : url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
                background-size: 6px;
                border-radius  : .15em;
                z-index        : -1;
            }

            &::after {
                content      : '';
                position     : absolute;
                top          : 0;
                left         : 0;
                width        : 100%;
                height       : 100%;
                background   : var(--pcr-color);
                border       : 1px solid $black-05;
                border-radius: 0.15em;
                box-sizing   : border-box;
            }

            &:hover {
                filter: brightness(1.05);
            }

            &:not(.pcr-active) {
                box-shadow: none;
            }
        }
    }

    .pcr-interaction {
        display    : flex;
        flex-wrap  : wrap;
        align-items: center;
        margin     : 0 -0.2em 0 -0.2em;

        >* {
            margin: 0 0.2em;
        }

        input {
            letter-spacing: 0.07em;
            font-size     : 0.75em;
            text-align    : center;
            cursor        : pointer;
            color         : #75797e;
            background    : #f1f3f4;
            border-radius : .15em;
            transition    : all 0.15s;
            padding       : 0.45em 0.5em;
            margin-block-start    : 0.75em;

            &:hover {
                filter: brightness(0.975);
            }

            &:focus {
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(66, 133, 244, 0.75);
            }
        }

        .pcr-result {
            color        : #75797e;
            text-align   : left;
            flex         : 1 1 8em;
            min-width    : 8em;
            transition   : all 0.2s;
            border-radius: .15em;
            background   : #f1f3f4;
            cursor       : text;

            &::-moz-selection,
            &::selection {
                background: #4285f4;
                color     : $white;
            }
        }

        .pcr-type.active {
            color     : $white;
            background: #4285f4;
        }

        .pcr-save,
        .pcr-cancel,
        .pcr-clear {
            color: $white;
            width: auto;
        }

        .pcr-save,
        .pcr-cancel,
        .pcr-clear {
            color: $white;
        }

        .pcr-save:hover,
        .pcr-cancel:hover,
        .pcr-clear:hover {
            filter: brightness(0.925);
        }

        .pcr-save {
            background: #4285f4;
        }

        .pcr-clear,
        .pcr-cancel {
            background: #f44250;
        }

        .pcr-clear:focus,
        .pcr-cancel:focus {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(244, 66, 80, 0.75);
        }
    }

    .pcr-selection {
        .pcr-picker {
            position           : absolute;
            height             : 18px;
            width              : 18px;
            border             : 2px solid $white;
            border-radius      : 100%;
            -webkit-user-select: none;
            -moz-user-select   : none;
            -ms-user-select    : none;
            user-select        : none;
        }

        .pcr-color-palette,
        .pcr-color-chooser,
        .pcr-color-opacity {
            position           : relative;
            -webkit-user-select: none;
            -moz-user-select   : none;
            -ms-user-select    : none;
            user-select        : none;
            display            : flex;
            flex-direction     : column;
            cursor             : grab;
            cursor             : -webkit-grab;
        }

        .pcr-color-palette:active,
        .pcr-color-chooser:active,
        .pcr-color-opacity:active {
            cursor: grabbing;
            cursor: -webkit-grabbing;
        }
    }

    &[data-theme='monolith'] {
        width    : 14.25em;
        max-width: 95vw;
        padding  : 0.8em;

        .pcr-selection {
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
            flex-grow      : 1;

            .pcr-color-preview {
                position       : relative;
                z-index        : 1;
                width          : 100%;
                height         : 1em;
                display        : flex;
                flex-direction : row;
                justify-content: space-between;
                margin-bottom  : 0.5em;

                &::before {
                    position       : absolute;
                    content        : '';
                    top            : 0;
                    left           : 0;
                    width          : 100%;
                    height         : 100%;
                    background     : url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
                    background-size: .5em;
                    border-radius  : .15em;
                    z-index        : -1;
                }

                .pcr-last-color {
                    cursor       : pointer;
                    transition   : background-color 0.3s, box-shadow 0.3s;
                    border-radius: 0.15em 0 0 0.15em;
                    z-index      : 2;
                }

                .pcr-current-color {
                    border-radius: 0 0.15em 0.15em 0;
                }

                .pcr-last-color,
                .pcr-current-color {
                    background: var(--pcr-color);
                    width     : 50%;
                    height    : 100%;
                }
            }

            .pcr-color-palette {
                width  : 100%;
                height : 8em;
                z-index: 1;

                .pcr-palette {
                    border-radius: .15em;
                    width        : 100%;
                    height       : 100%;

                    &::before {
                        position       : absolute;
                        content        : '';
                        top            : 0;
                        left           : 0;
                        width          : 100%;
                        height         : 100%;
                        background     : url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
                        background-size: .5em;
                        border-radius  : .15em;
                        z-index        : -1;
                    }
                }
            }

            .pcr-color-chooser,
            .pcr-color-opacity {
                height    : 0.5em;
                margin-block-start: 0.75em;
            }

            .pcr-color-chooser .pcr-picker,
            .pcr-color-opacity .pcr-picker {
                top      : 50%;
                transform: translateY(-50%);
            }

            .pcr-color-chooser .pcr-slider,
            .pcr-color-opacity .pcr-slider {
                flex-grow    : 1;
                border-radius: 50em;
            }

            .pcr-color-chooser .pcr-slider {
                background: linear-gradient(to right, red, #ff0, lime, cyan, blue, #f0f, red);
            }

            .pcr-color-opacity .pcr-slider {
                background     : linear-gradient(to right, transparent, black), url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
                background-size: 100%, 0.25em;
            }
        }
    }
}

@supports (display: grid) {
    .pcr-app .pcr-swatches {
        display              : grid;
        align-items          : center;
        grid-template-columns: repeat(auto-fit, 1.75em);
    }
}